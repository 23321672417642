import React, { useState } from 'react';
import './App.css';

function App() {
  // Zustände für den Betrag, den Trinkgeldprozentsatz und das Ergebnis der Berechnung
  const [betrag, setBetrag] = useState('');
  const [trinkgeldProzentsatz, setTrinkgeldProzentsatz] = useState(5);
  const [ergebnis, setErgebnis] = useState(null);
  // Funktionen zum Aktualisieren des Betrags und des Trinkgeldprozentsatzes
  const handleBetragChange = (event) => {
    setBetrag(event.target.value);
  };

  const handleTrinkgeldChange = (event) => {
    setTrinkgeldProzentsatz(parseInt(event.target.value, 10));
  };
  // Funktion zur Berechnung des Trinkgelds und Gesamtbetrags
  const berechneTrinkgeld = () => {
    const betragFloat = parseFloat(betrag);
    // Überprüfung, ob der eingegebene Betrag gültig ist
    if (!isNaN(betragFloat)) {
      const trinkgeldBetrag = (betragFloat * trinkgeldProzentsatz) / 100;
      const gesamtBetrag = betragFloat + trinkgeldBetrag;
      setErgebnis({ trinkgeldBetrag, gesamtBetrag });
    } else {
      // Setze das Ergebnis auf null, wenn der eingegebene Betrag ungültig ist
      setErgebnis(null);
    }
  };

  return (
    <div className="App">
      <h1>Trinkgeld Rechner</h1>
      <label>
        Betrag:
        <input type="number" value={betrag} onChange={handleBetragChange} />
      </label>
      <br />
      <label>
        Trinkgeld:
        <select value={trinkgeldProzentsatz} onChange={handleTrinkgeldChange}>
          <option value="5">5%</option>
          <option value="10">10%</option>
          <option value="15">15%</option>
        </select>
      </label>
      <br />
      <button onClick={berechneTrinkgeld}>Berechnen</button>
      <br />
      {ergebnis && (
        <div>
          <p>Trinkgeld: {ergebnis.trinkgeldBetrag.toFixed(2)}€</p>
          <p>Gesamt: {ergebnis.gesamtBetrag.toFixed(2)}€</p>
        </div>
      )}
      <footer>
        <p>2024 Hanno Liehmann</p>
      </footer>
    </div>
  );
}

export default App;
